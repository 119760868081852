@use '../util' as u;

// Helpers

// borders
.border {
  border: 1px solid lightslategray;
}
.b-p {
  border: 1px solid pink;
}
.b-r {
  border: 1px solid orangered;
}
.b-b {
  border: 1px solid lightskyblue;
}
.b-y {
  border: 1px solid gold;
}
.b-g {
  border: 1px solid greenyellow;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

// Content wrappers

.body-width {
  width: 100vw;
}

.content-width {
  width: 86vw;
}

.content-height {
  height: 100vh;
}

// Grids

.grid {
  display: grid;
}

.grid--place-center {
  place-items: center;
}

.grid--align-c-center {
  align-content: center;
}

.grid--align-c-end {
  align-content: end;
}

.grid--align-c-between {
  align-content: space-between;
}

.grid--just-i-right {
  justify-items: right;
}

// Flex

.flex--just-apart {
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-baseline {
  align-self: baseline;
}

// Styles

.upper {
  text-transform: uppercase;
}

.title {
  margin-top: u.rem(70);
  font-size: var(--font-title-mob);
}

.subtitle {
  font-size: var(--font-subtitle-mob);
}

.body-copy {
  font-size: var(--font-body-m);
  line-height: var(--line-height-m);
}

@media (min-width: 58em) {
  .title {
    margin-top: u.rem(30);
    margin-bottom: u.rem(80);
    font-size: var(--font-title-desk);
  }
  .subtitle {
    font-size: var(--font-subtitle-desk);
  }
}
