html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: var(--petal);
  color: var(--blackberry);
  font-family: var(--fontBody);
}

h1,
h2,
h3 {
  font-family: "Playfair Display", serif;
  font-weight: 800;
  line-height: 1.2;
}

a,
a:visited,
a:active {
  color: var(--blackberry);
  text-decoration: none;
  display: inline-block;
}

a:hover {
  color: var(--pinkGuava);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

object {
  pointer-events: none;
  display: block;
}

address {
  all: inherit;
}
