html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: var(--petal);
  color: var(--blackberry);
  font-family: var(--fontBody);
}

h1,
h2,
h3 {
  font-family: "Playfair Display", serif;
  font-weight: 800;
  line-height: 1.2;
}

a,
a:visited,
a:active {
  color: var(--blackberry);
  text-decoration: none;
  display: inline-block;
}

a:hover {
  color: var(--pinkGuava);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

object {
  pointer-events: none;
  display: block;
}

address {
  all: inherit;
}

.border {
  border: 1px solid lightslategray;
}

.b-p {
  border: 1px solid pink;
}

.b-r {
  border: 1px solid orangered;
}

.b-b {
  border: 1px solid lightskyblue;
}

.b-y {
  border: 1px solid gold;
}

.b-g {
  border: 1px solid greenyellow;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.body-width {
  width: 100vw;
}

.content-width {
  width: 86vw;
}

.content-height {
  height: 100vh;
}

.grid {
  display: grid;
}

.grid--place-center {
  place-items: center;
}

.grid--align-c-center {
  align-content: center;
}

.grid--align-c-end {
  align-content: end;
}

.grid--align-c-between {
  align-content: space-between;
}

.grid--just-i-right {
  justify-items: right;
}

.flex--just-apart {
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-baseline {
  align-self: baseline;
}

.upper {
  text-transform: uppercase;
}

.title {
  margin-top: 4.375rem;
  font-size: var(--font-title-mob);
}

.subtitle {
  font-size: var(--font-subtitle-mob);
}

.body-copy {
  font-size: var(--font-body-m);
  line-height: var(--line-height-m);
}

@media (min-width: 58em) {
  .title {
    margin-top: 1.875rem;
    margin-bottom: 5rem;
    font-size: var(--font-title-desk);
  }

  .subtitle {
    font-size: var(--font-subtitle-desk);
  }
}
:root {
  --blackberry: hsl(266, 8%, 18%);
  --rusty: hsl(24, 74%, 51%);
  --orange: #FF7F50;
  --brightOrange:hsl(11, 80%, 60%);
  --brightRedPink: hsl(343, 100%, 39%);
  --brightPink: #DE3163;
  --pinkGuava: hsl(354, 63%, 60%);
  --peach: hsl(6.2,87.9%,93.5%);
  --petal: hsl(43, 100%, 99%);
  --shadow: rgba(45, 42, 49, 0.5);
}

:root {
  --fontDisplay: "Playfair Display", serif;
  --fontBody: "Montserrat", sans-serif;
  --font-body-s: 14px;
  --font-body-m: 16px;
  --font-body-l: 18px;
  --font-subtitle-mob: 20px;
  --font-subtitle-desk: 28px;
  --font-title-mob: 28px;
  --font-title-desk: 42px;
  --font-display-mob: 34px;
  --font-display-desk: 64px;
  --line-height-m: 1.6;
  --text-size-scaler: 10vw;
  --text-size-huge: 72px;
}

button {
  font-family: inherit;
  /* For all browsers */
  line-height: 1.15;
  /* For all browsers */
  margin: 0;
  /* Firefox and Safari have margin */
  overflow: visible;
  /* Edge hides overflow */
  text-transform: none;
  /* Firefox inherits text-transform */
  -webkit-appearance: button;
  /* Safari otherwise prevents some styles */
  color: var(--petal);
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.btn {
  font-family: inherit;
  font-size: var(--font-body-s);
  border: 0;
  border-radius: 3.125rem;
  background: linear-gradient(90deg, var(--brightOrange) 0%, var(--brightPink) 100%);
  color: var(--petal);
  line-height: 1.2;
  letter-spacing: 1px;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.875rem 1.75rem;
  cursor: pointer;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  display: inline-block;
}

.btn:hover {
  color: var(--petal);
  transform: translateY(-1px);
  box-shadow: rgba(45, 42, 49, 0.1) 0px 4px 6px -1px, rgba(45, 42, 49, 0.06) 0px 2px 4px -1px;
}

.btn-talk {
  margin-top: 1.25rem;
  justify-self: center;
}

.btn-view {
  justify-self: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.btn-text {
  color: var(--petal);
}

.btn-social {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.75rem 0.8125rem;
  background: var(--petal);
  color: var(--blackberry);
  display: block;
}

.btn-social:hover {
  color: var(--brightPink);
}

@media (min-width: 58em) {
  .btn {
    font-size: var(--font-body-m);
  }
}
.header {
  height: 2.5rem;
}

.logomark {
  width: 1rem;
}

.menu-icon-moblie {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 9;
}
.menu-icon-moblie > span {
  display: block;
  width: 1.375rem;
  height: 0.125rem;
  background-color: var(--blackberry);
  transition: all 300ms ease-in-out;
}
.menu-icon-moblie > span:first-child {
  margin-bottom: 0.375rem;
}
.menu-icon-moblie > span:last-child {
  width: 1rem;
}

.menu-toggle > span:first-child {
  transform: rotate(45deg);
}
.menu-toggle > span:last-child {
  width: 1.375rem;
  transform: translateY(-8px) rotate(-45deg);
}

.open {
  visibility: visible !important;
  opacity: 0.95 !important;
}

.overlay-menu-mobile {
  text-align: center;
  visibility: hidden;
  background-color: var(--peach);
  transform: translateY(0);
  transition: all 0.375s ease-in-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 3.125rem 1.5625rem;
}
.overlay-menu-mobile > li {
  padding: 1.25rem 0;
}

.ul-desktop > li {
  margin-left: 2.25rem;
}

@media (max-width: 48em) {
  .menu-desktop {
    display: none;
  }
}
@media (min-width: 48.0625em) {
  .menu-icon-moblie {
    display: none;
  }

  .header {
    height: 3.125rem;
    margin-bottom: 2.5rem;
  }
}
.landing {
  height: 80vh;
  justify-self: center;
}

.landing__img-container {
  min-height: 36vh;
  place-items: center;
}

.landing__svg {
  width: 11.75rem;
  transform: translate(10%, 20%);
}

.landing__info-container {
  text-align: center;
  align-content: space-between;
}

.landing__info--title {
  font-size: var(--font-display-mob);
}

.landing__info--body {
  font-size: var(--font-body-m);
  line-height: 1.5;
}

.btn-talk {
  justify-self: center;
}

@media (min-width: 58em) {
  .landing {
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "left right";
    gap: 1.25rem;
    width: 70vw;
    height: 66vh;
  }

  .landing__img-container {
    grid-area: right;
  }

  .landing__svg {
    width: 17.5rem;
    transform: translateY(-25%);
  }

  .landing__info-container {
    text-align: left;
    align-content: center;
  }

  .landing__info--title {
    font-size: var(--font-display-desk);
  }

  .landing__info--body {
    font-size: var(--font-body-l);
    line-height: 1.7;
  }

  .btn-talk {
    justify-self: start;
  }
}
.work {
  max-width: 100vw;
  height: 850px;
  background-image: url("../assets/work-bkg-big.svg");
  background-size: auto auto;
  background-repeat: no-repeat;
  background-position: left;
  text-align: center;
  gap: 3.125rem;
}

.work-content {
  gap: 1.25rem;
}

.work-icon {
  width: 6.5625rem;
  justify-self: center;
  transform: translateX(10%);
  margin-top: 1.875rem;
}

.dev-icons {
  gap: 20px;
  justify-self: center;
}

.dev-icon {
  font-size: 1rem;
  display: block;
}

.img-icon {
  width: 1rem;
}

.work-body-copy {
  padding: 0 1.25rem;
}

@media (min-width: 58em) {
  .work {
    background-size: cover;
  }

  .work-content {
    width: 70vw;
  }

  .work-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .work-icon {
    width: 8.75rem;
  }

  .dev-icon {
    font-size: 1.75rem;
  }

  .img-icon {
    width: 1.75rem;
  }

  .work-body-copy {
    padding: 0 3.75rem;
  }
}
.projects {
  text-align: center;
  justify-content: center;
}

.projects-content {
  width: 86vw;
  gap: 2.5rem;
}

.project-card {
  background-color: var(--peach);
  border-radius: 0.9375rem;
  overflow: hidden;
  box-shadow: rgba(45, 42, 49, 0.1) 0px 4px 6px -1px, rgba(45, 42, 49, 0.06) 0px 2px 4px -1px;
  margin-top: 1.875rem;
}

.card-img {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 16.25rem;
}

.nia {
  background-image: url("../assets/nia-lesedi.png");
}

.area {
  background-image: url("../assets/area-layouts-big.png");
}

.notes {
  background-image: url("../assets/super-sticky-notes.png");
}

.card-text {
  padding: 1.25rem;
  min-height: 22.5rem;
  align-content: space-between;
}

.body-copy--link {
  display: block;
}

@media (min-width: 58em) {
  .projects-content {
    width: 70vw;
    margin-bottom: 6.25rem;
  }

  .project-card {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "left right";
  }

  .area {
    grid-area: right;
  }

  .card-text {
    text-align: left;
    padding: 1.25rem 3.75rem;
  }

  .btn-view {
    justify-self: start;
    margin-top: 3.125rem;
    margin-bottom: 2.25rem;
  }
}
.about {
  justify-content: center;
}

.about-content {
  width: 86vw;
  text-align: center;
}

.profile-pic {
  width: 8.75rem;
  justify-self: center;
  transform: translateX(10%);
  margin-top: 1.875rem;
}

.about-body-copy {
  margin: 1.875rem 0;
  padding: 1.25rem 0;
}

@media (min-width: 58em) {
  .about {
    margin-bottom: 3.75rem;
  }

  .about-content {
    width: 70vw;
  }

  .profile-pic {
    margin-top: 0;
  }

  .about-body-copy {
    padding: 1.25rem 3.75rem;
  }
}
.footer {
  justify-content: center;
  max-width: 100vw;
  background-image: url("../assets/footer-bkg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.contact {
  text-align: center;
}

.contact-social {
  justify-content: center;
  margin-top: 1.875rem;
}

.btn-lin {
  margin-right: 1.875rem;
}

.footer-nav {
  margin-top: 3.75rem;
  margin-bottom: 0.625rem;
}

.logo {
  display: block;
  width: 4.0625rem;
}

.ul-footer {
  font-size: var(--font-body-s);
}
.ul-footer > li {
  margin-left: 1.875rem;
}

@media (min-width: 58em) {
  .contact-container {
    margin-top: 3.125rem;
  }

  .footer-nav {
    margin-top: 6.25rem;
    margin-bottom: 0.9375rem;
  }

  .btn-talk--footer {
    margin-top: 0;
  }

  .logo {
    width: 5rem;
  }

  .ul-footer {
    font-size: var(--font-body-m);
  }
}