@use '../util' as u;

:root {
    --fontDisplay: 'Playfair Display', serif;
    --fontBody: 'Montserrat', sans-serif;

    --font-body-s: 14px;
    --font-body-m: 16px;
    --font-body-l: 18px;

    --font-subtitle-mob: 20px;
    --font-subtitle-desk: 28px;

    --font-title-mob: 28px;
    --font-title-desk: 42px;

    --font-display-mob: 34px;
    --font-display-desk: 64px;

    --line-height-m: 1.6;

    --text-size-scaler: 10vw;
    --text-size-huge: 72px;
}