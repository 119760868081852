@use '../util/' as u;

// Normalize
button {
  font-family: inherit; /* For all browsers */
  line-height: 1.15; /* For all browsers */
  margin: 0; /* Firefox and Safari have margin */
  overflow: visible; /* Edge hides overflow */
  text-transform: none; /* Firefox inherits text-transform */
  -webkit-appearance: button; /* Safari otherwise prevents some styles */
  color: var(--petal);
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

// Styles

.btn {
  font-family: inherit;
  font-size: var(--font-body-s);
  border: 0;
  border-radius: u.rem(50);
  background: linear-gradient(
    90deg,
    var(--brightOrange) 0%,
    var(--brightPink) 100%
  );
  color: var(--petal);
  line-height: 1.2;
  letter-spacing: 1px;
  white-space: nowrap;
  text-decoration: none;
  padding: u.rem(14) u.rem(28);
  cursor: pointer;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  display: inline-block;
}

.btn:hover {
  color: var(--petal);
  transform: translateY(-1px);
  box-shadow: rgba(45, 42, 49, 0.1) 0px 4px 6px -1px, rgba(45, 42, 49, 0.06) 0px 2px 4px -1px;
}

.btn-talk {
  margin-top: u.rem(20);
  justify-self: center;
}

.btn-view {
  justify-self: center;
  margin-top: u.rem(20);
  margin-bottom: u.rem(20);
}

.btn-text {
  color: var(--petal);
}

.btn-social {
  height: u.rem(40);
  width: u.rem(40);
  padding: u.rem(12) u.rem(13);
  background: var(--petal);
  color: var(--blackberry);
  display: block;
}

.btn-social:hover {
  color: var(--brightPink);
}

@media (min-width: 58em) {
  .btn {
    font-size: var(--font-body-m);
  }
}

