@use '../util' as u;

.footer {
  justify-content: center;
  max-width: 100vw;
  background-image: url("../assets/footer-bkg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.contact {
  text-align: center;
}

.contact-social {
  justify-content: center;
  margin-top: u.rem(30);
}

.btn-lin {
  margin-right: u.rem(30);
}

.footer-nav {
  margin-top: u.rem(60);
  margin-bottom: u.rem(10);
}

.logo {
  display: block;
  width: u.rem(65);
}

.ul-footer {
  font-size: var(--font-body-s);
  > li {
    margin-left: u.rem(30);
  }
}

@media (min-width: 58em) {
  .contact-container {
    margin-top: u.rem(50);
  }
  .footer-nav {
    margin-top: u.rem(100);
    margin-bottom: u.rem(15);
  }
  .btn-talk--footer {
    margin-top: 0;
  }
  .logo {
    width: u.rem(80);
  }
  .ul-footer {
    font-size: var(--font-body-m);
  }
}
