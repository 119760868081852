@use '../util' as u;

.header {
  height: u.rem(40);
}

.logomark {
  width: u.rem(16);
}

// Hamburger menu for mobile
.menu-icon-moblie {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 9;
  > span {
    display: block;
    width: u.rem(22);
    height: u.rem(2);
    background-color: var(--blackberry);
    transition: all 300ms ease-in-out;

    &:first-child {
      margin-bottom: u.rem(6);
    }

    &:last-child {
      width: u.rem(16);
    }
  }
}

// Makes the hamburger menu turn to an x
.menu-toggle {
  > span:first-child {
    transform: rotate(45deg);
  }
  > span:last-child {
    width: u.rem(22);
    transform: translateY(-8px) rotate(-45deg);
  }
}

// Menu that shows only when mobile menu is clicked

// When opened change the overlay-nav-menu-mobile:
.open {
  visibility: visible !important;
  opacity: 0.95 !important;
}

.overlay-menu-mobile {
  text-align: center;

  visibility: hidden;
  background-color: var(--peach);
  transform: translateY(0);
  transition: all 0.375s ease-in-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1;
  padding: u.rem(50) u.rem(25);

  > li {
    padding: u.rem(20) 0;
  }
}

// Desktop Menu

.ul-desktop {
  > li {
    margin-left: u.rem(36);
  }
}

// Media Queries

@media (max-width: 48em) {
  .menu-desktop {
    display: none;
  }
}

@media (min-width: 48.0625em) {
  .menu-icon-moblie {
    display: none;
  }
  .header {
    height: u.rem(50);
    margin-bottom: u.rem(40);
  }
}
