:root {
  --blackberry: hsl(266, 8%, 18%);
  --rusty: hsl(24, 74%, 51%);
  --orange: #FF7F50;
  --brightOrange:hsl(11, 80%, 60%);
  --brightRedPink: hsl(343, 100%, 39%);
  --brightPink: #DE3163;
  --pinkGuava: hsl(354, 63%, 60%);
  --peach: hsl(6.2,87.9%,93.5%);
  --petal: hsl(43, 100%, 99%);
  --shadow: rgba(45, 42, 49, 0.5);
}
