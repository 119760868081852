@use '../util/' as u;

.landing {
  height: 80vh;
  justify-self: center;
}

.landing__img-container {
  min-height: 36vh;
  place-items: center;
}

.landing__svg {
  width: u.rem(188);
  transform: translate(10%, 20%);
}

.landing__info-container {
  text-align: center;
  align-content: space-between;
}

.landing__info--title {
  font-size: var(--font-display-mob);
}

.landing__info--body {
  font-size: var(--font-body-m);
  line-height: 1.5;
}

.btn-talk {
  justify-self: center;
}

@media (min-width: 58em) {
  .landing {
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "left right";
    gap: u.rem(20);
    width: 70vw;
    height: 66vh;
  }
  .landing__img-container {
    grid-area: right;
    // justify-content: start;
  }
  .landing__svg {
    width: u.rem(280);
    transform: translateY(-25%);
  }
  .landing__info-container {
    text-align: left;
    align-content: center;
  }
  .landing__info--title {
    font-size: var(--font-display-desk);
  }
  .landing__info--body {
    font-size: var(--font-body-l);
    line-height: 1.7;
  }
  .btn-talk {
    justify-self: start;
  }
}
